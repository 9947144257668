import React, {useState} from "react";
import {AthleteForm} from "./AthleteForm";
import {Success} from "./Success";
import {OgElements} from "../../common/OgElements";

const Athlete = () => {

    const [athleteEmail, setAthleteEmail] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    return <>
        <OgElements description={'Щоб стати асоційованим членом ГО "Батл Маел Україна" та отримати можливість брати учась в змаганнях Battle Mile заповніть форму.'} title={"Реєстрація - Battle Mile"} />
        <div>
            {
                !showSuccess ?
                    <AthleteForm setAthleteEmail={setAthleteEmail} setShowSuccess={setShowSuccess} /> :
                    <Success email={athleteEmail} />
            }
        </div>
    </>
}

export { Athlete }