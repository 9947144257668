import React, {useCallback, useEffect, useState} from "react";
import InputMask from 'react-input-mask';

const ChildAthleteFormBody = ({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            genders,
                            skillLevels,
                            clubs,
                            countries
                         }) => {

    const [showDropDownClubs, setShowDropDownClubs] = useState(true);

    useEffect(() => {
        if (values && values.skillLevel && skillLevels) {
            const profiSkillLevel = skillLevels.find(item => item.alias === 'profi')

            setShowDropDownClubs(profiSkillLevel.id === parseInt(values.skillLevel))
        }
    }, [values])

    const handleChangeCustomClubName = useCallback((e) => {
        setFieldValue('club', '');
        setFieldValue('customClubName', e.target.value);
    }, [setFieldValue])

    const handleClubSelect = useCallback((e) => {
        setFieldValue('customClubName', '');
        setFieldValue('club', e.target.value)
    }, [setFieldValue])

    return <form onSubmit={handleSubmit} autoComplete="off">

        <div className="form-group">
            <label htmlFor="second">Прізвище *</label>
            {/*<input className="form-control" type="text" id="second" placeholder="Введіть Прізвище" name="lastName" value={values.lastName} onChange={handleChange} autoComplete="off" />*/}
            <input className="form-control" type="text" placeholder="Введіть Прізвище" value={values.lastName} onChange={e => setFieldValue('lastName', e.target.value.trim())} name={"lastName"} />
            <div className="form-error">{errors.lastName && touched.lastName ? errors.lastName : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="name">Ім’я *</label>
            {/*<input className="form-control" type="text" id="name" placeholder="Введіть Ім’я"  onChange={handleChange} value={values.name} autoComplete="off" />*/}
            <input className="form-control" type="text" placeholder="Введіть Ім’я"  onChange={e => setFieldValue('name', e.target.value.trim())} value={values.name}  name={"name"}/>
            <div className="form-error">{errors.name && touched.name ? errors.name : null}</div>
        </div>

        <div className="form-group">
            <label>Стать *:</label>
            {
                genders &&
                genders.map(item => <div className="radio mb-1" key={`g-${item.id}`}>
                    <input type="radio" name="gender" id={`gender-${item.id}`} value={item.id} onChange={handleChange} />
                    <label htmlFor={`gender-${item.id}`}>
                        {item.name}
                    </label>
                </div>)
            }
            <div className="form-error">{errors.gender && touched.gender ? errors.gender : null}</div>
        </div>
        <div className="form-group">
            <label>Дата народження *</label>
            {/*<input type="text" className="form-control" data-toggle="input-mask" name="birthday" data-mask-format="00/00/0000" placeholder="ДД/MM/РРРР" onChange={handleChange} value={values.birthday} autoComplete="off"/>*/}
            {/*<input type="text" className="form-control" data-toggle="input-mask" data-mask-format="00/00/0000" placeholder="ДД/MM/РРРР" name={"birthday"} onChange={e => setFieldValue('birthday', e.target.value)} value={values.birthday} />*/}
            {/*<span className="font-13 text-muted">"ДД/MM/РРРР"</span>*/}
            <InputMask name={"birthday"} className={"form-control"} mask={"99/99/9999"} maskChar={null} placeholder="ДД/MM/РРРР" onChange={e => setFieldValue('birthday', e.target.value)} value={values.birthday} />
            <div className="form-error">{errors.birthday && touched.birthday ? errors.birthday : null}</div>
        </div>
        <div className="form-group">
            <label>Моб. телефон *</label>
            {/*<input type="text" className="form-control" data-toggle="input-mask" data-mask-format="(000) 00-00-000" placeholder="(xxx) xx-xx-xxx" name="phone" onChange={handleChange} value={values.phone} autoComplete="off"/>*/}
            {/*<input type="text" className="form-control" data-toggle="input-mask" data-mask-format="(000) 00-00-000" placeholder="(xxx) xx-xx-xxx" name={"phone"} onChange={e => setFieldValue('phone', e.target.value) } value={values.phone} />*/}
            {/*<span className="font-13 text-muted"> "(xxx) xx-xx-xxx"</span>*/}
            <InputMask name={"phone"} className={'form-control'} placeholder={"(xxx) xx-xx-xxx"} maskChar={null} mask={"(999) 99-99-999"} onChange={e => setFieldValue('phone', e.target.value)} value={values.phone} />
            <div className="form-error">{errors.name && touched.name ? errors.name : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="emailaddress">Email address *</label>
            <input className="form-control" type="email" placeholder="Введіть email" name={"email"} onChange={e => setFieldValue('email', e.target.value.trim())} value={values.email}  />
            <div className="form-error">{errors.email && touched.email ? errors.email : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="country">Країна *</label>
            {/*<input className="form-control" type="text" placeholder="Введіть країну" name={"country"} onChange={e => setFieldValue('country', e.target.value)} value={values.country} />*/}
            <select id={"country"} className={"form-control"} name={"country"} onChange={handleChange}>
                <option value="">Вибрати..</option>
                {
                    countries && countries.map(item => <option key={item} value={item}>{item}</option>)
                }
            </select>
            <div className="form-error">{errors.country && touched.country ? errors.country : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="city">Місто *</label>
            <input className="form-control" type="text" placeholder="Введіть місто" name={"city"} onChange={e => setFieldValue('city', e.target.value.trim())} value={values.city} />
            <div className="form-error">{errors.city && touched.city ? errors.city : null}</div>
        </div>

        <div className="form-group">
            <label htmlFor="skillLevel">Категорія навичок *:</label>
            <select id="skillLevel" className="form-control" name="skillLevel" onChange={handleChange}>
                <option value="">Вибрати..</option>
                {
                    skillLevels && skillLevels.map(item => <option key={`skll-${item.id}`} value={item.id}>{item.name}</option>)
                }
            </select>
            <div className="skill-level-desc">{(skillLevels && values && values.skillLevel) && skillLevels.find(item => item.id === parseInt(values.skillLevel)).description}</div>
            <div className="form-error">{errors.skillLevel && touched.skillLevel ? errors.skillLevel : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="result1">Особистий на 1500 метрів або 1 милю</label>
            <InputMask mask={"99:99.99"} maskChar={null} className={"form-control"} placeholder={"хв:ск.мс"} value={values.bestPersonalTime} onChange={e => setFieldValue('bestPersonalTime', e.target.value)} name={"bestPersonalTime"} />
            {/*<input className="form-control" type="text" data-toggle="input-mask"*/}
            {/*       data-mask-format="00:00.00" placeholder="хв:ск.мс" value={values.bestPersonalTime}*/}
            {/*       onChange={e => setFieldValue('bestPersonalTime', e.target.value)} name={"bestPersonalTime"} />*/}
            <div className="form-error">{errors.bestPersonalTime && touched.bestPersonalTime ? errors.bestPersonalTime : null}</div>
        </div>
        <div className="form-group">
            <label htmlFor="result">Найкращий результат сезону на 1500 метрів або 1 милю</label>
            <InputMask mask={"99:99.99"} maskChar={null} className={"form-control"} placeholder={"хв:ск.мс"} value={values.bestSeasonTime} onChange={e => setFieldValue('bestSeasonTime', e.target.value)} name={"bestSeasonTime"} />
            {/*<input className="form-control" type="text" data-toggle="input-mask"*/}
            {/*       data-mask-format="00:00.00" placeholder="хв:ск.мс" value={values.bestSeasonTime}*/}
            {/*       onChange={e => setFieldValue('bestSeasonTime', e.target.value)} name={"bestSeasonTime"}/>*/}
            <div className="form-error">{errors.bestSeasonTime && touched.bestSeasonTime ? errors.bestSeasonTime : null}</div>
        </div>
        {
            showDropDownClubs ?
                <div className="form-group">
                    <label htmlFor="heard">Бажаний клуб:</label>
                    <select id="heard" className="form-control" name="club" onChange={handleClubSelect}>
                        <option value="">Вибрати..</option>
                        {
                            clubs && clubs.map(item => <option value={item.id} key={`club-${item.id}`}>{item.name}</option>)
                        }
                    </select>
                    <div className="form-error">{errors.club && touched.club ? errors.club : null}</div>
                </div> :
                <div className="form-group">
                    <label htmlFor="heard">Клуб:</label>
                    <input className="form-control" type="text" id="result" name="customClubName"
                           placeholder="Введіть назву клубу" onChange={handleChangeCustomClubName}
                           onBlur={handleChangeCustomClubName} value={values.customClubName}  />
                    <div className="form-error">{errors.club && touched.club ? errors.club : null}</div>
                </div>
        }
        <div className="form-group">
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="checkbox-dataConfirmed" name="dataConfirmed" onChange={handleChange} />
                <label className="custom-control-label" htmlFor="checkbox-dataConfirmed">
                    Так, я підтверджую, що вказані мною персональні дані є актуальними. <a href="https://battlemile.org/wp-content/uploads/2020/10/zgoda-na-obrobku-personalnih-danih.pdf">Умови обробки даних.</a>
                </label>
                <div className="form-error">{errors.dataConfirmed && touched.dataConfirmed ? errors.dataConfirmed : null}</div>
            </div>
        </div>
        {/*<div className="form-group">*/}
        {/*    <div className="custom-control custom-checkbox">*/}
        {/*        <input type="checkbox" className="custom-control-input" id="checkbox-signup" name="terms" onChange={handleChange} />*/}
        {/*        <label className="custom-control-label" htmlFor="checkbox-signup">*/}
        {/*            Так, я ознайомлений(а) зі <a href="https://battlemile.org/wp-content/uploads/2020/10/ctatut-bmu.pdf">Статутом ГО "Батл Маел Україна"</a>.*/}
        {/*        </label>*/}
        {/*        <div className="form-error">{errors.terms && touched.terms ? errors.terms : null}</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className="form-group mb-0 text-center">
            <button className="btn btn-success btn-block" type="submit"> Зареєструватись</button>
        </div>
    </form>
}

export {ChildAthleteFormBody}