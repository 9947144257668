import React, {useCallback, useEffect, useState} from "react";
import {Formik} from "formik";
import {athleteInitData} from "./duck/constans";
import {athleteSchema} from "./duck/schema";
import {AthleteFormBody} from "./AthleteFormBody";

import logo2 from '../../assert/images/logo-2.svg';
import logo1 from '../../assert/images/logo-1.svg';
import {registrationAthlete} from "../../api/Athlete";
import {Preloader} from "../../common/Preloader/Preloader";
import axios from 'axios';
import {getClubs, getCountries, getGenders, getSkillLevels} from "../../api/Dictionary";

const AthleteForm = ({setShowSuccess, setAthleteEmail}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [clubs, setClubs] = useState(null);
    const [genders, setGenders] = useState(null);
    const [skillLevels, setSkillLevels] = useState(null);
    const [countries, setCountries] = useState(null);

    const handleSubmit = useCallback((values) => {
        const {
            terms,
            dataConfirmed,
            promoCode,
            ...jsonData
        } = values

        setIsLoading(true)
        registrationAthlete({
            ...jsonData,
            club: jsonData.club ? {id: jsonData.club} : null,
            gender: jsonData.gender ? {id: jsonData.gender} : null,
            skillLevel: jsonData.skillLevel ? {id: jsonData.skillLevel} : null
        }, `${window.location.origin}/payment`, promoCode)
            .then(res => {
                if (res.data.status === 200) {
                    setAthleteEmail(values.email);
                    setShowSuccess(true)
                }
            })
            .finally(() => setIsLoading(false))
    }, [setShowSuccess, setAthleteEmail])

    useEffect(() => {
        axios.all([getGenders(), getSkillLevels(), getClubs(), getCountries()])
            .then(axios.spread((...res) => {
                const genderRes = res[0];
                const skillLevelsRes = res[1];
                const clubsRes = res[2];
                const countriesRes = res[3];

                setGenders(genderRes.data.data);
                setSkillLevels(skillLevelsRes.data.data);
                setClubs(clubsRes.data.data);
                setCountries(countriesRes.data.data);
            }))
            .catch(err => console.log(err))
    }, [])

    return <>
        <div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-pattern">

                            <div className="card-body p-4">

                                <div className="text-center w-75 m-auto">
                                    <div className="auth-logo">
                                        <a href="index.html" className="logo logo-dark text-center">
                                            <span className="logo-lg">
                                                <img src={logo2} alt="" height="22" />
                                            </span>
                                        </a>

                                        <a href="index.html" className="logo logo-light text-center">
                                            <span className="logo-lg">
                                                <img src={logo1} alt="" height="22" />
                                            </span>
                                        </a>
                                    </div>
                                    <div className="form-text-info mb-4 mt-3">
                                        Щоб стати асоційованим членом ГО "Батл Маел Україна" та отримати можливість брати учась в змаганнях Battle Mile заповніть форму.
                                        <p className="form-text-info__pay-info">Увага! Реєстрація є платною, та складає 150 грн.</p>
                                    </div>
                                    {/*<p className="text-muted mb-4 mt-3">Ще не зареєструвались? Це займе не більше хвилини</p>*/}
                                </div>

                                {/*<Formik enableReinitialize initialValues={athleteInitData} onSubmit={handleSubmit} validationSchema={athleteSchema} autocomplete={"off"}>*/}
                                {/*    {*/}
                                {/*        props => <AthleteFormBody {...props}*/}
                                {/*                                  skillLevels={skillLevels}*/}
                                {/*                                  genders={genders}*/}
                                {/*                                  clubs={clubs} />*/}
                                {/*    }*/}
                                {/*</Formik>*/}

                                {
                                    isLoading ?
                                        <Preloader loading={isLoading} /> :
                                        <Formik initialValues={athleteInitData} onSubmit={handleSubmit} validationSchema={athleteSchema}>
                                            {
                                                props => <AthleteFormBody {...props}
                                                                          skillLevels={skillLevels}
                                                                          genders={genders}
                                                                          clubs={clubs}
                                                                          countries={countries} />
                                            }
                                        </Formik>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer className="footer footer-alt text-white-50">

        </footer>
    </>
}

export {AthleteForm}