import API from "./API";

export const getGenders = () => {
    return API.get('/dictionary/genders');
}

export const getSkillLevels = () => {
    return API.get('/dictionary/skill-levels');
}

export const getClubs = () => {
    return API.get('/club/all')
}

export const getCountries = () => {
    return API.get('/dictionary/countries');
}