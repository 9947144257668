import React, {useEffect, useState} from "react";
import {getWayForPayData} from "../../api/WayForPayAPI";
import {useParams} from "react-router-dom";

const PaymentWayForPay = () => {

    const params = useParams();
    const [wayForPayData, setWayForPayData] = useState(null)

    useEffect(() => {
        getWayForPayData(params.token)
            .then(res => {
                if (res.data.status === 200 && res.data.data) {
                    setWayForPayData({
                        merchantAccount: res.data.data.merchantAccount,
                        merchantDomainName: res.data.data.merchantDomainName,
                        orderReference: res.data.data.orderReference,
                        orderDate: res.data.data.orderDate,
                        amount: res.data.data.amount,
                        currency: res.data.data.currency,
                        productName: res.data.data.productName,
                        productCount: res.data.data.productCount,
                        productPrice: res.data.data.productPrice,
                        merchantSignature: res.data.data.signature,
                        merchantAuthType: 'SimpleSignature',
                        defaultPaymentSystem: "card",
                        orderTimeout: '49000',
                        serviceUrl: res.data.data.serviceUrl,
                        merchantTransactionSecureType: "AUTO"
                    })
                }
            })
            .catch(err => console.log(err))
    }, [params.token])

    // useEffect(() => {
    //     console.log(wayForPayData)
        // if (wayForPayData) {
        //     sendDataToWatForPay(wayForPayData)
        //         .then(res => {
        //             console.log("res: ", res)
        //         })
        //         .catch(err => {
        //             if (err.response && err.response.status === 303) {
        //                 console.log("do redirect")
        //             } else {
        //                 console.log("note a redirect")
        //                 // Handle error however you want
        //             }
        //         })
        // }
    // }, [wayForPayData])

    return <>
        {
            !wayForPayData ? null :
                <form method="post" action="https://secure.wayforpay.com/pay" acceptCharset="utf-8">
                    <input name="merchantAccount" value={wayForPayData.merchantAccount} style={{display: 'none'}} readOnly/>
                    <input name="merchantAuthType" value="SimpleSignature" style={{display: 'none'}} readOnly/>
                    <input name="merchantDomainName" value={wayForPayData.merchantDomainName} style={{display: 'none'}} readOnly/>
                    <input name="orderReference" value={wayForPayData.orderReference} style={{display: 'none'}} readOnly/>
                    <input name="orderDate" value={wayForPayData.orderDate} style={{display: 'none'}} readOnly/>
                    <input name="amount" value={wayForPayData.amount} style={{display: 'none'}} readOnly/>
                    <input name="currency" value={wayForPayData.currency} style={{display: 'none'}} readOnly/>
                    <input name="orderTimeout" value="49000" style={{display: 'none'}} readOnly/>
                    <input name="productName[]" value={wayForPayData.productName[0]} style={{display: 'none'}} readOnly/>
                    <input name="productPrice[]" value={wayForPayData.productPrice[0]} style={{display: 'none'}} readOnly/>
                    <input name="productCount[]" value={wayForPayData.productCount[0]} style={{display: 'none'}} readOnly/>
                    <input name="defaultPaymentSystem" value="card" style={{display: 'none'}} readOnly/>
                    <input name="merchantSignature" value={wayForPayData.merchantSignature} style={{display: 'none'}} readOnly/>
                    <input name="serviceUrl" value={wayForPayData.serviceUrl} style={{display: 'none'}} readOnly/>
                    <input name="returnUrl" value="https://battlemile.org/" style={{display: 'none'}} readOnly/>
                    <div className="greeting-text">
                        <h1>Вітаємо!</h1>
                        <p>Для завершення реєстрації, потрібно зробити внесок.</p>
                    </div>
                    <input type="submit" value="Перейти до сплати" className="btn btn-success btn-block" />
                </form>
        }
    </>
}

export { PaymentWayForPay }