import API from "./API";

export const registrationAthlete = (jsonData, confirmUrl, promoCode) => {
    const params = {
        confirmUrl: confirmUrl
    }

    if (promoCode) {
        params.promoActiveCode = promoCode;
    }

    return API.post('/athlete/registration', jsonData, {
        params: params,
    })
}

export const existAthleteEmail = (email) => {
    return API.get('/athlete/email-exist', {
        params: {
            email: email
        }
    })
}

export const existPromoCode = (promoCode) => {
    return API.get(`/promo-code/exists/${promoCode}`);
}