export const athleteInitData = {
    name: '',
    lastName: '',
    gender: '',
    birthday: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    bestPersonalTime: '',
    bestSeasonTime: '',
    club: '',
    customClubName: '',
    skillLevel: '',
    dataConfirmed: false,
}