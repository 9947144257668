import React from 'react';

import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {Payment} from "./app/components/Payment";
import {Athlete} from "./app/components/Athlete/Athlete";
import {ChildAthlete} from "./app/components/ChildAthleteRegistration/ChildAthlete";
import {Welcome} from "./app/components/Welcome";

function App() {
  return <Router>
    <Switch>
      <Route path={"/welcome"} component={Welcome} />
      <Route path={["/athlete/registration"]} component={Athlete} />
      <Route path={["/athlete/child-registration"]} component={ChildAthlete} />
      <Route path={["/payment/:token"]} component={Payment} />
      <Redirect to={"/welcome"} />
      {/*<Redirect to={"/athlete/registration"} />*/}
    </Switch>
  </Router>
}

export default App;