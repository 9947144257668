import React from "react";
import {Helmet} from "react-helmet";

const OgElements = ({ title, description }) => {

    return <Helmet>
        { title && <title>{title}</title> }
        { title && <meta property="og:title" content={title} /> }
        { description && <meta property="og:description" content={description}/> }
        { description && <meta name="description" content={description}/> }
    </Helmet>
}

export { OgElements }