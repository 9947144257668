import React from "react";
import {PaymentResult} from "./PaymentResult";
import {PaymentWayForPay} from "./PaymentWayForPay";

const PaymentProcess = ({data}) => {

    return <>
        {
            data.isComplete ?
                <PaymentResult data={data} /> :
                <PaymentWayForPay data={data} />
        }
    </>
}

export { PaymentProcess }