import React from "react";

const Preloader = ({loading, backgroundColor, loaderColor}) => {
    return <>
        {
            loading ?
                <div className="loading-background" style={{"backgroundColor": backgroundColor}}>
                    <div className="loading-bar" >
                        <div className="loading-circle-1" style={{"backgroundColor": loaderColor}} />
                        <div className="loading-circle-2" style={{"backgroundColor": loaderColor}} />
                    </div>
                </div> :
                null
        }
    </>
}

Preloader.defaultProps = {
    loading: false,
    backgroundColor: 'rgba(236, 240, 241, 0.7)',
    loaderColor: '#e74c3c'
}

export {Preloader}