import React from "react";

const SimpleTextComponent = ({text}) => {

    return <div className={"simple-text"} style={{width: "100%", textAlign: "center", color: "white", marginTop: "10%", fontSize: "20px"}} >
        {
            text && text
        }
    </div>
}

SimpleTextComponent.defaultProps = {
    text: ''
}

export { SimpleTextComponent }