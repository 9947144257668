import React from "react";
import {SimpleTextComponent} from "../../common/Text/SimpleTextComponent";

const PaymentResult = ({data}) => {

    return <div style={{width: "100%", textAlign: "center", color: "white", marginTop: "10%", fontSize: "20px"}}>
        {
            data.isFree ? <SimpleTextComponent text={"Реєстрація безкоштовна"} /> : <SimpleTextComponent text={"Оплата закінчена"} />
        }
    </div>
}

export { PaymentResult }