import * as yup from "yup";
import {existAthleteEmail, existPromoCode} from "../../../api/Athlete";
import moment from "moment";

export const athleteSchema = yup.object().shape({
    name: yup.string().required('Обов\'язкове поле'),
    lastName: yup.string().required('Обов\'язкове поле'),
    gender: yup.string().required('Обов\'язкове поле'),
    birthday: yup.string()
        .matches(/\d\d\/\d\d\/\d\d\d\d/, {
            message:"Некортний формат дати, введіть за форматом ДД/MM/РРРР",
            excludeEmptyString: true
        })
        .test('date-rage-check ', 'Введена дата не є те що може існувати', (value, context) => {
            const regrex = new RegExp("([0-2]{1}[0-9]{1}|30|31)\\/(0[1-9]|1[0-2])\\/[0-9]{4}");
            let dateRes = true;

            if (value) {
                // const currentYear = new Date().getFullYear();
                // const birthYear = value.split('/')[2];
                // if (birthYear && birthYear.length === 4) dateRes = currentYear > parseInt(birthYear)

                const birthday = moment(value, "DD/MM/YYYY");
                dateRes = birthday.isBetween(moment('1940-01-01'), moment());
            }

            return regrex.test(value) && dateRes;
        })
        .test('date-rage-check ', 'Атлети до 13 років повинні викортиати іншу форму для реєстрації', (value, context) => {
            let dateRes = true;
            if (value && value.length === 10) {
                const periodYear = moment.duration(moment().diff(moment(value, "DD/MM/YYYY"))).get('years');
                dateRes = periodYear >= 14;
            }

            return dateRes;
        })
        .required('Обов\'язкове поле'),
    phone: yup.string().required('Обов\'язкове поле'),
    email: yup.string()
        .email('Формат поля тільки для email')
        .required('Обов\'язкове поле')
        .test('email-exist', 'Ця пошта вже зареєстрованна',  async (value, context) => {
            if (value) {
                let exist;
                await existAthleteEmail(value)
                    .then(res => exist = !res.data.data)

                return exist;
            }

            return true;
        }),
    country: yup.string().required('Обов\'язкове поле'),
    city: yup.string().required('Обов\'язкове поле'),
    bestPersonalTime: yup.string()
        .nullable()
        .matches(/\d\d:\d\d\.\d\d/, {
            message: "Введіть час в форматі: хв:ск.мс",
            excludeEmptyString: true
        }),
    bestSeasonTime: yup.string()
        .nullable()
        .matches(/\d\d:\d\d\.\d\d/, {
            message: "Введіть час в форматі: хв:ск.мс",
            excludeEmptyString: true
        }),
    skillLevel: yup.string().required('Обов\'язкове поле'),
    terms: yup.boolean().oneOf([true], 'Необхідно ознайомитись та прийняти умови'),
    dataConfirmed: yup.boolean().oneOf([true], 'Необхідно ознайомитись та прийняти умови'),
    club: yup.string().nullable(),
    customClubName: yup.string().nullable(),
    promoCode: yup.string()
        .nullable()
        .test('email-promo-code', 'Невалідний код',  async (value, context) => {
            if (value) {
                let exist = false;
                await existPromoCode(value)
                    .then(res => exist = res.data.data)

                return exist;
            }

            return true;
        }),
});