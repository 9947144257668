import React from "react";
import { useHistory } from "react-router-dom";

const Welcome = () => {

    const history = useHistory();

    document.title = "Ласкаво просимо";

    return <div className={"welcome-wrapper"}>
        <div className={"welcome__info"}>
            <p>Оберіть форму для реєстрації.</p>
            <button className="btn btn-success" onClick={() => {history.push('/athlete/registration')}}> Реєстрація атлета віком ВІД 14</button>
            <button className="btn btn-success" onClick={() => {history.push('/athlete/child-registration')}}> Реєстрація атлета віком ДО 14</button>
        </div>
    </div>
}

export {Welcome}