import React, {useEffect, useState} from "react";
import {getAthleteRegistration} from "../../api/PaymentAPI";
import { useParams } from "react-router-dom";
import {Preloader} from "../../common/Preloader/Preloader";
import {PaymentProcess} from "./PaymentProccess";
import {SimpleTextComponent} from "../../common/Text/SimpleTextComponent";

const Payment = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null);
    const [displayInfoText, setDisplayInfoText] = useState(false)

    const params = useParams();

    document.title = "Оплата"

    useEffect(() => {
        if (params.token) {
            setIsLoading(true)
            getAthleteRegistration(params.token)
                .then((res) => {
                    if (res.data.status === 200) {
                        setData(res.data.data)
                    } else if (res.data.status === 404) {
                        setDisplayInfoText(true);
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [params.token])

    return <div className="payment-root">
        { isLoading && <Preloader loading={isLoading} /> }
        { !isLoading && data && <PaymentProcess data={data} /> }
        { !isLoading && !data && <SimpleTextComponent text={"Час життя посилання закінчився, шукайте нове посилання на пошті. Також перевірте папку \"Спам\"."} /> }
    </div>
}

export {Payment}