import React from "react";

import logo2 from '../../assert/images/logo-2.svg';
import logo1 from '../../assert/images/logo-1.svg';
import {ReactComponent as SuccessIcon} from "../../assert/SuccessIcon.svg"

const Success = ({email}) => {

    return <>
        <div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-pattern">

                            <div className="card-body p-4">

                                <div className="text-center w-75 m-auto">
                                    <div className="auth-logo">
                                        <a href="https://battlemile.org/" className="logo logo-dark text-center">
                                            <span className="logo-lg">
                                                <img src={logo2} alt="" height="22" />
                                            </span>
                                        </a>

                                        <a href="https://battlemile.org/" className="logo logo-light text-center">
                                            <span className="logo-lg">
                                                <img src={logo1} alt="" height="22" />
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                <div className="mt-3 text-center">
                                    <SuccessIcon />

                                    <h3>Success !</h3>
                                    <p className="text-muted font-14 mt-2"> Email було відправлено
                                        на <b>{email}</b>.
                                        Будь ласка, перевірте електронну пошту від компанії та натисніть на включене
                                        посилання. </p>

                                    <a href="https://battlemile.org/" className="btn btn-block waves-effect waves-light mt-3"
                                       style={{background: '#cf251b', color:'white'}}>Повернутися на сайт</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer className="footer footer-alt text-white-50">

        </footer>

        {/*<script src="../assets/js/vendor.min.js"></script>*/}
        {/*<script src="../assets/js/app.min.js"></script>*/}
    </>
}

export {Success}