import API from "./API";
import Axios from "axios";

export const sendDataToWatForPay = (data) => {
    // const tmpAxios = Axios.create({
    //     withCredentials: true,
    //     validateStatus: function (status) {
    //         console.log("status: ", status)
    //         return (status >= 200 && status < 300) || status === 303; // default
    //     },
    //     transformResponse: [function (data) {
    //         console.log('transformResponse.data: ', data)
    //         return data;
    //     }],
    //     crossDomain: false,
    //     mode: 'no-cors',
    //     credentials: 'same-origin',
    // });

    return Axios.post(`https://secure.wayforpay.com/pay`, new URLSearchParams(data).toString(), {
        headers: {
            'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })

    // return tmpAxios.post(`https://secure.wayforpay.com/pay`, convertToFormData(data), {
    //     headers: {
    //         'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         // 'Access-Control-Allow-Origin' : '*',
    //         // 'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     },
    // });
}

export const getWayForPayData = (token) => {
    return API.get(`/purchase/signature/${token}`);
}